import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ControlData } from '../dashboard/models/control-data';
import { RunPeriod } from '../dashboard/models/run-period';
import { DashboardService } from '../dashboard/services/dashboard.service';
import { CommonService } from '../shared/common-services/common.service';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { WarningcomponentComponent } from '../warningcomponent/warningcomponent.component';
import { MatDialog } from '@angular/material/dialog';

declare var _satellite: any;
declare var analyticsDataLayer: any;

@Component({
  selector: 'app-dashboard-details',
  templateUrl: './dashboard-details.component.html',
  styleUrls: ['./dashboard-details.component.scss']
})

export class DashboardDetailsComponent implements OnInit {
  allControlMetaData: any;
  [x: string]: any;
  //controlList: ControlData[] = [];
  //displayedColumns: string[] = ['controlName', 'runTimestamp', 'sourceName'];
  @Input() form?: FormGroup;
  displayedColumns: string[] = [];
  //dataSource = [];

  dataSchema = {};
  sourceNames: any = [];
  controlName: string;
  runPeriods: RunPeriod[] = [];
  selectedRunTimestamp: string;
  sourceName: string;
  lastRunAdditionalData: [];
  controlDisplayName: string;
  errorMsg: string = '';
  showCheckbox: boolean = false;
  groupNames = [];
  originalData: any;
  isChecked: boolean = false;
  public window: any = window;

  //dataSource = new MatTableDataSource(ELEMENT_DATA);
  dataSource: MatTableDataSource<ControlData>;
  //ControlsDataSource: MatTableDataSource<ControlData>;


  @ViewChild(MatSort) sort: MatSort;

  constructor(private route: ActivatedRoute, private router: Router, private spinnerService: SpinnerService, private dashboardService: DashboardService, private commonService: CommonService, public dialog: MatDialog) { }


  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap
    this.controlName = routeParams.get("controlName");
    this.selectedRunTimestamp = routeParams.get("lastRunTimestamp");
    this.sourceName = routeParams.get("sourceName");
    this.lastRunAdditionalData = this.commonService.getData(this.controlName);
    this.controlDisplayName = this.commonService.getData(this.controlName + "displayName");
    this.refreshRunPeriod();
    this.refreshTableData();
    this.trackOmniture('dashboarddetails/' + this.controlName + '/' + this.selectedRunTimestamp);
    this.getAllControlMetaData(this.controlName);
    this.getDashbaord();
  }

  trackOmniture(pageName: string) {
    let loggedInUser;
    if (!loggedInUser)
      loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser && loggedInUser.userName) {
      analyticsDataLayer.pageInfo.pageName = pageName;
      analyticsDataLayer.pageInfo.userID = loggedInUser.userName.split('@')[0];
      _satellite.track("pageview");
    }
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // On input focus: setup filterPredicate to only filter by input column
  setupFilter(column: string) {
    this.dataSource.filterPredicate = (d: any, filter: string) => {
      const textToSearch = d[column] && d[column].toLowerCase() || '';
      if (column === "Region")
        return textToSearch == filter
      else
        return textToSearch.indexOf(filter) !== -1;
    };
  }

  refreshTableData() {
    this.spinnerService.showSpinner();
    this.dashboardService.getDashboardDetails(this.controlName, this.selectedRunTimestamp, this.sourceName).subscribe(data => {
      this.displayedColumns = data.displayedColumns;
      this.dataSource = new MatTableDataSource(data.dataSource);
      //setTimeout(() => this.ControlsDataSource.paginator = this.paginator);
      setTimeout(() => this.dataSource.sort = this.sort);
      this.isGridEmpty();
      this.spinnerService.hideSpinner();
    },
      (err: any) => {
        console.log("Error details control runperiod ", err);
        this.spinnerService.hideSpinner();
      }
    );
  }

  refreshRunPeriod() {
    this.spinnerService.showSpinner();
    this.dashboardService.getRunPeriods(this.controlName, this.sourceName).subscribe(data => {
      this.runPeriods = data;
      this.runperiod = this.runPeriods.filter(x => x.runTimestamp == this.selectedRunTimestamp)[0];
      this.isChecked = this.runperiod != null ? this.runperiod.isChecked : false;
      this.isGridEmpty();
      if (this.data)
        setTimeout(() => this.data.sort = this.sort);
    },
      (err: any) => {
        console.log("Error loading runperiod ", err);
        this.spinnerService.hideSpinner();
      }
    );
  }

  runperiod: any;
  onRunPeriodChanged(selectedRunTimestamp: string) {
    this.isChecked = false;
    this.selectedRunTimestamp = selectedRunTimestamp;
    this.runperiod = this.runPeriods.filter(x => x.runTimestamp == selectedRunTimestamp)[0];
    this.isChecked = this.runperiod != null ? this.runperiod.isChecked: false;
    this.refreshRoute();
    this.refreshTableData()
  }

  onSourceClicked(sourceName: string) {
    this.sourceName = sourceName;
    this.refreshRoute();
    this.refreshRunPeriod();
    this.refreshTableData();
  }

  refreshRoute() {
    var url = "/dashboarddetails/" + this.controlName + "/" + this.selectedRunTimestamp + "/" + this.sourceName;
    this.router.navigateByUrl(url);
  }

  downloadDashboardDetailsControls() {
    this.spinnerService.showSpinner();
    this.dashboardService.downloadDashboardDetailsControls(this.controlName, this.selectedRunTimestamp, this.sourceName).subscribe((data: any) => {
      var blob = this.commonService.convertBase64ToBlobData(data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"); //new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      var url = window.URL.createObjectURL(blob);

      var link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", this.controlDisplayName + "_" + this.sourceName + "_" + this.selectedRunTimestamp + ".xlsx");
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link)
      this.spinnerService.hideSpinner();
    },
      //(err: any) => {
      //  this.toastr.error(err);
      //  this.spinnerservice.hidespinner();
      //}
    );
  }

  errorMsg: string = '';
  isGridEmpty() {
    if (this.runPeriods.length > 0 && this.runPeriods.filter(x => x.processedFile && x.processedFile.toLowerCase() == 'no files' && x.runTimestamp == this.selectedRunTimestamp).length > 0)
      this.errorMsg = 'The requested file was not found for the Control.Please Contact ITS team';
    else if (this.dataSource && this.dataSource.data && this.dataSource.data.length == 0)
      this.errorMsg = 'Yayee !! There are No Non-Compliant records found';
    else
      this.errorMsg = '';
  }

  hasAccess(groupName: string, securityAction: string): boolean {
    return this.commonService.hasAccess(groupName, securityAction);
  }

  getAllControlMetaData(controlName: string) {
    this.spinnerService.showSpinner();
    this.dashboardService.getAllControlMetaData().subscribe((result: any) => {
      this.allControlMetaData = result.filter(c => c.isUpload == true && c.controlName == controlName);
      if (this.allControlMetaData.length > 0) {
        this.showCheckbox = true;
      }
      this.spinnerService.hideSpinner();
    });
  }

  updateComplianceStatus() {
    this.groupNames.push(this.controlName);
    this.dashboardService.getAllDashboardControls(this.groupNames).subscribe((result: any) => {
      if (result != null) {
        this.originalData = result;
        let complianceStatus = "Compliant";
        this.originalData[0].complianceStatus = complianceStatus;
      }
      this.dashboardService.addDashboard(this.originalData[0]).subscribe();
      /*this.runperiod.isChecked = this.isChecked;*/
      this.runperiod.isChecked = true;
      this.dashboardService.addRunperiod(this.runperiod).subscribe();;
      this.spinnerService.hideSpinner();
    });
  }

  selectionChanged(event: any) {
    if (event.target.checked) {
      this.isChecked = true;
    }
    else {
      this.isChecked = false;
    }
  }

  save() {
    let warningmsg = "Are you sure you want to re-calculate Compliance Status?";
    let Headermsg = "Confirmation Message";
    this.openDialog(Headermsg, warningmsg)
    //if (this.window.confirm("Are you sure you want to re-calculate Compliance Status?")) {
    //  if (this.isChecked) {
    //    this.updateComplianceStatus();
    //  }
    //}
  }

  openDialog(text, displaymsg): void {
    const dialogRef = this.dialog.open(WarningcomponentComponent, {
      data: { headerText: text, msg: displaymsg }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null || result != undefined) {
        if (this.isChecked && result==true) {
          this.updateComplianceStatus();
        }
      }

    });
  }

  isDisable(): boolean{
    var isDisabled = true;    
    if (this.runPeriods.length == 0) {
      isDisabled= true;
    }
    else if ((this.selectedRunTimestamp == this.originalData[0].lastRunAdditionalInfo.filter(item => item.sourceName == this.sourceName)[0].lastRunTimeStamp) || (this.runPeriods.reverse()[0].runTimestamp == this.selectedRunTimestamp)) {
      isDisabled= false;
    } 
    return isDisabled;
  }

  getDashbaord() {
    this.groupNames.push(this.controlName);
    this.dashboardService.getAllDashboardControls(this.groupNames).subscribe((result: any) => {
      if (result != null) {
        this.originalData = result;
      }
      this.spinnerService.hideSpinner();
    });
  }
}
