import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { AttestationForm, AttestationFormResponse, AttestationStatus, VendorDetails, MasterData, AttestationStatusText } from './models/question-base';
import { QuestionControlService } from './services/question-control.service';
import { QuestionService } from './services/question.service';
import { of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../shared/common-services/common.service';
import { DynamicFormQuestionComponent } from './dynamic-form-question.component'
import { DashboardService } from '../dashboard/services/dashboard.service';
import { ConfigService } from '../shared/common-services/config.service';
import { QuestionType } from '../manage-questionnaire/models/questionnaire';
import { newArray } from '@angular/compiler/src/util';

declare var _satellite: any;
declare var analyticsDataLayer: any;

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [QuestionControlService, QuestionService, CommonService]
})
export class DynamicFormComponent implements OnInit {

  questions: AttestationForm[];
  tempQuestions: AttestationForm[];
  previousAttestations: any;
  attestationFormResponse: AttestationFormResponse[];
  form?: FormGroup;
  payLoad = '';
  controlDesc = '';
  attestationStatus: number;
  previousAttestationStatus: number;
  controlName: string;
  timeStamp: string;
  successMessage: string;
  masterData: any = {};
  AttestationStatusApprove = AttestationStatus.Approved;
  AttestationStatusReject = AttestationStatus.Rejected;
  AttestationStatusRevision = AttestationStatus.SentForRevision;
  lastActivityBy: string;
  lastActivityDate: Date;
  queueNotification: any;
  @ViewChild('questionComponent') questionComponent: DynamicFormQuestionComponent;
  constructor(private qcs: QuestionControlService, private qs: QuestionService, private fb: FormBuilder,
    private route: ActivatedRoute, private router: Router, private config: ConfigService, private spinnerService: SpinnerService, private toastr: ToastrService, private commonService: CommonService, private dashboardService: DashboardService) {
  }

  ngOnInit() {
    const routeParams = this.route.snapshot.paramMap
    this.controlName = routeParams.get("controlName");
    this.timeStamp = routeParams.get("timeStamp");
    this.getAttestationForm();
    this.getPreviousAttestations();
    if (this.controlName == 'Network' || this.controlName == 'Telecom')
      this.getMasterData();
    this.trackOmniture('attestation/' + this.controlName + '/' + this.timeStamp);
  }

  trackOmniture(pageName: string) {
    let loggedInUser;
    if (!loggedInUser)
      loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (loggedInUser && loggedInUser.userName) {
      analyticsDataLayer.pageInfo.pageName = pageName;
      analyticsDataLayer.pageInfo.userID = loggedInUser.userName.split('@')[0];
      _satellite.track("pageview");
    }
  }

  getMasterData() {
    this.commonService.getMasterData(["Vendor", "DeviceType"]).subscribe((result: any) => {
      if (result && result["Vendor"].length > 0) {
        this.masterData.tempVendorList = result["Vendor"];
      }
      else {
        this.masterData.tempVendorList = [];
      }
      if (result && result["DeviceType"].length > 0) {
        this.masterData.tempDeviceTypeList = result["DeviceType"];
      }
      else {
        this.masterData.tempDeviceTypeList = [];
      }
    });
  }

  getAttestationForm() {
    this.spinnerService.showSpinner();
    this.qs.getAttestationFormById(this.controlName, this.timeStamp).subscribe(data => {
      this.questions = data;
      this.controlDesc = this.questions[0].controlDisplayName;
      this.attestationStatus = this.questions[0].status;
      this.previousAttestationStatus = this.questions[0].status;
      if (this.attestationStatus == AttestationStatus.Approved || this.attestationStatus == AttestationStatus.SentForRevision) {
        this.lastActivityBy = this.questions[0].attestedBy;
        this.lastActivityDate = new Date(this.questions[0].attestationDate);
      }
      else {
        this.lastActivityBy = this.questions[0].submittedBy;
        this.lastActivityDate = new Date(this.questions[0].attestationSubmittedDate);
      }
      if (this.controlName == 'Network' || this.controlName == 'Telecom') {
        if (this.questions.filter(q => q.isReferenceQuestion).length > 0) {
          this.tempQuestions = data;
          this.questions = this.questions.filter(q => !q.isReferenceQuestion);
          data.forEach((question) => {
            if (question.questionType == QuestionType.grid) {
              if (question.vendorDetails && question.vendorDetails.length > 0) {
                question.vendorDetails.forEach((vendor) => {
                  if (vendor.isVisible == true) {
                    this.questions.push(data.filter(q => q.questionId == vendor.diligenceQuestionId)[0]);
                  }
                });
              }
            }
          });
        }

      }
      this.form = this.qcs.toFormGroup(this.questions);
      of((this.questions.sort((a, b) => a.questionOrder - b.questionOrder))).subscribe(sortedQuestions => {
        this.questions = sortedQuestions;
      });
      setTimeout(() => {
        this.disableForm();
      });
      this.spinnerService.hideSpinner();
    },
      (err: any) => {
        this.toastr.error(err);
        this.spinnerService.hideSpinner();
      }
    );
  }

  disableForm() {
    let isDisabled = false;
    let roleNames = this.commonService.getRoleNames(this.controlName);
    if (roleNames.length == 1 && roleNames.indexOf("View Only") != -1)
      isDisabled = true;
    else if (roleNames.indexOf("Administrator") == -1 && roleNames.indexOf("Contributor") != -1 && this.attestationStatus == AttestationStatus.SentForReview)
      isDisabled = true;
    else if (this.attestationStatus == AttestationStatus.Approved || this.attestationStatus == AttestationStatus.Rejected)
      isDisabled = true;
    if (isDisabled) {
      this.form.disable();
      if (this.questionComponent.fileUploader)
        this.questionComponent.fileUploader.nativeElement.disabled = true;
    }

  }

  getPreviousAttestations() {
    this.qs.getPreviousAttestationForms(this.controlName).subscribe(data => {
      this.previousAttestations = data.previousAttestations;
    });
  }

  getAttestationStatus(status: number): string {
    if (!status)
      status = this.attestationStatus;
    if (status == 0 && sessionStorage.getItem("overDueStatus")) {
      return sessionStorage.getItem("overDueStatus");
    }
    return this.commonService.getAttestationStatus(status, this.controlName);
  }

  navigateToAttestationForm(controlName: string, timeStamp: string) {
    location.href = '/attestation/' + controlName + '/' + timeStamp;
  }

  addQuestion(data: any) {
    if (data.action == "insert") {
      var referenceQuestion = Object.create(this.tempQuestions.filter(q => q.isReferenceQuestion)[0]);
      referenceQuestion.questionId = data.id
      referenceQuestion.answers = "";
      referenceQuestion.questionOrder = referenceQuestion.questionOrder + data.vendorsCount;
      referenceQuestion.isReferenceQuestion = true;
      this.questions.push(referenceQuestion);
      of((this.questions.sort((a, b) => a.questionOrder - b.questionOrder))).subscribe(sortedQuestions => {
        this.questions = sortedQuestions;
      });
      this.form.addControl(referenceQuestion.questionId, referenceQuestion.isMandatory ? new FormControl("", [Validators.required])
        : new FormControl(""));
    }
    else {
      this.questions.splice(this.questions.indexOf(this.questions.filter(q => q.questionId == data.id)[0]), 1)
      this.form.removeControl(data.id);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
      //if (control instanceof FormControl) {
      //  control.markAsTouched({ onlySelf: true });
      //} else if (control instanceof FormGroup) {
      //  this.validateAllFormFields(control);
      //}
    });
  }

  validateFiles() {
    let isValid = true;
    this.questions.forEach((q: AttestationForm) => {
      if (isValid && q.questionType == QuestionType.file) {
        if (this.form.get(q.questionId).value) {
          const control = this.form.get(q.questionId);
          isValid = control.valid
          control.markAsTouched({ onlySelf: true });
        }
      }
    });
    return isValid;
  }



  onSubmit() {
    if (this.form.valid) {
      this.attestationStatus = AttestationStatus.SentForReview;
      this.successMessage = "Submitted Successfully";
      this.saveAttestationForm();
    }
    else
      this.validateAllFormFields(this.form);
  }

  saveAsDraft() {
    if (this.validateFiles()) {
      this.successMessage = "Saved Successfully";
      this.saveAttestationForm();
    }
  }

  saveAttestationForm() {
    this.spinnerService.showSpinner();
    let files: Array<File> = new Array<File>();;
    this.attestationFormResponse = new Array<AttestationFormResponse>();
    var loggedInUser = JSON.parse(localStorage.getItem("loggedInUser")); 
    if (this.tempQuestions && this.tempQuestions.length > 0) {
      var referenceQuestion = this.tempQuestions.filter(f => f.isReferenceQuestion)[0];
      let response = new AttestationFormResponse();
      response.status = this.attestationStatus;
      response.controlName = referenceQuestion.controlName;
      response.questionId = referenceQuestion.questionId
      response.questionnaireId = referenceQuestion.questionnaireId;
      response.timestamp = referenceQuestion.formCreatedStamp;
      response.answers = referenceQuestion.answers;
      if (this.attestationStatus == AttestationStatus.Approved || this.attestationStatus == AttestationStatus.Rejected || this.attestationStatus == AttestationStatus.SentForRevision) {
        response.attestedBy = loggedInUser.name;
      }
      else {
        response.submittedBy = loggedInUser.name;
      }
      this.attestationFormResponse.push(response);
    }
    this.questions.forEach((q: AttestationForm) => {
      let response = new AttestationFormResponse();
      q.status = this.attestationStatus;
      response.status = this.attestationStatus;
      response.controlName = q.controlName;
      response.questionId = q.questionId
      response.questionnaireId = q.questionnaireId;
      response.timestamp = q.formCreatedStamp;
      response.questionOrder = q.questionOrder;
      if (this.attestationStatus == AttestationStatus.Approved || this.attestationStatus == AttestationStatus.Rejected || this.attestationStatus == AttestationStatus.SentForRevision) {
        response.attestedBy = loggedInUser.name; 
      }
      else {
        response.submittedBy = loggedInUser.name;
      }
      this.lastActivityBy = loggedInUser.name;
      this.lastActivityDate = new Date();
      if (q.questionType == QuestionType.checkbox) {
        let selectedValues = [];
        var checkboxGroup = this.form.controls[q.questionId] as FormGroup
        q.options.forEach(opt => {
          if (checkboxGroup.controls[q.questionId + "-" + opt.id].value == true) {
            selectedValues.push(opt.id);
          }
        });

        response.answers = selectedValues.toString();
      }
      else if (q.questionType == QuestionType.grid) {
        var vendorFormArray = this.form.controls[q.questionId] as FormArray
        response.vendorDetails = new Array<VendorDetails>();
        vendorFormArray.controls.forEach((formGroup: FormGroup) => {
          if (this.attestationStatus == AttestationStatus.SentForReview) {
            formGroup.controls["isNew"].setValue(false);
            formGroup.controls["isEdit"].setValue(false);
          }
          let vendor = new VendorDetails();
          Object.keys(formGroup.controls).forEach(field => {
            vendor[field] = formGroup.get(field).value;
          });
          response.vendorDetails.push(vendor);
        });
      }
      else if (q.questionType == QuestionType.file) {
        response.fileName = q.fileName;
        files.push(this.form.controls[q.questionId].value);
      }
      else {
        response.answers = this.form.controls[q.questionId].value.toString();
      }
      this.attestationFormResponse.push(response);
    });


    this.qs.saveAttestationForm(this.attestationFormResponse, files).subscribe(data => {

      this.toastr.success(this.successMessage);
      if (this.attestationFormResponse[0].status == 1) {
        this.queueNotification = { "notificationTemplateId": "3", "controlName": this.controlName, "createdBy": loggedInUser.name};
        this.qs.addQueueNotification(this.queueNotification).subscribe(data => {
        });
      }
      else if (this.attestationFormResponse[0].status == 4) {
        this.queueNotification = { "notificationTemplateId": "4", "controlName": this.controlName, "createdBy": loggedInUser.name };
        this.qs.addQueueNotification(this.queueNotification).subscribe(data => {
        });
      }
      this.spinnerService.hideSpinner();
      this.getPreviousAttestations();
      this.reset();
      this.saveMasterData();
      this.updateDashboardStatus();
      if (AttestationStatus.Approved) {        
       
      }
    },
      (err: any) => {
        this.toastr.error(err);
        this.spinnerService.hideSpinner();
      }
    );
  }

  public reset() {
    this.questions.forEach((q: AttestationForm) => {
      if (q.questionType == QuestionType.file) {
        if (q.fileName)
          q.attachment = q.fileName;
        q.fileName = "";
        this.questionComponent.fileUploader.nativeElement.value = "";
      }
      if ((this.attestationStatus == AttestationStatus.SentForReview && !this.hasAccess(this.controlName, 'review'))
        || this.attestationStatus == AttestationStatus.Approved || this.attestationStatus == AttestationStatus.Rejected) {
        if (this.questionComponent.fileUploader)
          this.questionComponent.fileUploader.nativeElement.disabled = true;
        this.form.disable();
      }
    });
  }

  saveMasterData() {
    let newMasterData = new Array<MasterData>();
    this.attestationFormResponse.forEach((res) => {
      if (res.vendorDetails && res.vendorDetails.length > 0) {
        res.vendorDetails.forEach((vendor) => {
          if (this.masterData.tempVendorList.filter(t => t.name && t.name.toLowerCase() == vendor.vendorName.toLowerCase()).length == 0) {
            if (vendor.vendorName && vendor.vendorName.length > 0) {
              const m = new MasterData();
              m.id = this.createGuid();
              m.name = vendor.vendorName;
              m.type = "Vendor";
              newMasterData.push(m);
              this.masterData.tempVendorList.push(m);
            }
          }
          if (this.masterData.tempDeviceTypeList.filter(t => t.name && t.name.toLowerCase() == vendor.deviceType.toLowerCase()).length == 0) {
            if (vendor.deviceType && vendor.deviceType.length > 0) {
              const m = new MasterData();
              m.id = this.createGuid();
              m.name = vendor.deviceType;
              m.type = "DeviceType";
              newMasterData.push(m);
              this.masterData.tempDeviceTypeList.push(m);
            }
          }
        });
      }
    });
    if (newMasterData.length > 0) {
      this.commonService.saveMasterData(newMasterData).subscribe(res => {
      });
    }
  }
  updateDashboardStatus() {
    if (this.attestationStatus != this.previousAttestationStatus) {
      this.dashboardService.updateAttestationStatus(this.controlName, this.attestationStatus.toString()).subscribe((res) => {
      });
    }
  }
  createGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  showSubmitButtons() {
    //console.log(this.commonService.getRoleNames(this.controlName));
    return ((this.attestationStatus == 0 || this.attestationStatus == AttestationStatus.SentForRevision) &&
      this.commonService.getRoleNames(this.controlName).indexOf("Contributor") != -1) ||
      ((this.attestationStatus == 0 || this.attestationStatus == AttestationStatus.SentForRevision) &&
        this.hasAccess(this.controlName, 'review'))
  }
  showReviewButtons() {
    return this.attestationStatus == AttestationStatus.SentForReview && this.hasAccess(this.controlName, 'review')
  }

  hasAccess(groupName: string, action: string) {
    return this.commonService.hasAccess(groupName, action);
  }
  changeStatus(status: number) {
    if (this.form.valid) {
      this.attestationStatus = status;
      if (this.attestationStatus == AttestationStatus.Approved) {
        this.successMessage = 'Approved Successfully';
        var loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        let approvednotificationtemplate = {
          notificationTemplateId: "8",
          controlName: this.controlName,
          createdBy: loggedInUser.name
        }
        this.qs.sentNotification(approvednotificationtemplate).subscribe(msg => {

        }, (err: any) => {
          this.toastr.error(err);
        });
      }
      else if (this.attestationStatus == AttestationStatus.Rejected)
        this.successMessage = 'Rejected Successfully';
      else if (this.attestationStatus == AttestationStatus.SentForRevision)
        this.successMessage = 'Sent for revision Successfully';
      this.saveAttestationForm();
    }
    else
      this.validateAllFormFields(this.form);
  }

}
